import React, { useState } from 'react';
import '../css/About.scss';

const About = () => {
	const [showScroll, setShowScroll] = useState(false);

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 1040 && window.pageYOffset < 1600) {
			setShowScroll(true);
		} else if (
			showScroll &&
			(window.pageYOffset <= 1040 || window.pageYOffset >= 1600)
		) {
			setShowScroll(false);
		}
	};

	window.addEventListener('scroll', checkScrollTop);

	return (
		<div className="about" id="about">
			<div className="problem-text">
				<div className="problem">
					<p>
						The Plastic waste problem: Increased use and its non-compostability
					</p>
					<p>
						Today, fibers are in short supply, often chemical-laden and
						expensive.
					</p>
					<p>
						Crop stubble is being burned, causing extensive air pollution and
						destroying a precious resource.
					</p>
				</div>
				<div className="title-container">
					<h4>The Problem</h4>
				</div>
			</div>
			<div className="solution-text">
				<h6 className="solution-question">What if we could change the game?</h6>
				<p
					className="solution-answer"
					// style={{ opacity: showScroll ? '1' : '0' }}
				>
					Well, we did.
				</p>
			</div>
		</div>
	);
};

export default About;
