import './css/App.css';
import MainPage from './pages/MainPage';
import { Router } from '@reach/router';

const App = () => {
	return (
		<div>
			<Router default={false}>
				<MainPage path="/"/>
				<MainPage default/>
			</Router>
		</div>
	

	)
	
	
};

export default App;
