import React from 'react';
import { Link as ReactScrollLink } from 'react-scroll';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import '../css/Navbar.scss';

import Logo from '../images/LogoTransparent.png';

const SiteNavbar = () => {
	const pages = [
		{ href: 'home', name: 'Home', offset: -20 },
		{ href: 'about', name: 'About', offset: -20 },
		{ href: 'solutions', name: 'Solutions', offset: 370 },
		{ href: 'technology', name: 'Tech', offset: 300 },
		{ href: 'products', name: 'Products', offset: -40 },
		{ href: 'contact', name: 'Contact', offset: 200 },
	];
	return (
		<Navbar bg="transparent" expand="lg" className="navbar">
			<Navbar.Brand href="/">
				<img src={Logo} alt="Barracuda Technologies" className="logo" />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="navbar-nav" />
			<Navbar.Collapse id="navbar-nav">
				<Nav className="ml-auto nav">
					{pages.map((page) => (
						<ReactScrollLink
							className="scrolldown link-tag"
							activeClass="active"
							to={page.href}
							spy={true}
							smooth={true}
							offset={page.offset}
							duration={500}
							key={page.name}
						>
							<p className="nav-text">{page.name}</p>
						</ReactScrollLink>
					))}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default SiteNavbar;
