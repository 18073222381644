import React from 'react';
import About from './About';
import Contact from './Contact';
import Product from './Products';
import Tech from './Tech';
import Intro from './Intro';
import Solutions from './Solutions';

import meta from '../images/metadata.png';

import '../css/Main.scss';

import ProductBot from '../images/bg-elements/product-bot.png';
import ProductBotMobile from '../images/bg-elements-mobile/product-bot.png';

import ScrollArrow from '../components/ScrollArrow';

const MainPage = () => {
	return (
		<main className="main-page" id="home">
			<img src={meta} alt="Meta" style={{ display: 'none' }} />
			<Intro />
			<About />
			<Solutions />
			<Tech />
			<Product />
			<img
				src={ProductBot}
				alt="Products Conclusion"
				className="product-bot desktop"
				style={{ width: '100%' }}
			/>
			<img
				src={ProductBotMobile}
				alt="Products Conclusion"
				className="product-bot mobile"
				style={{ width: '100%' }}
			/>
			<Contact />
			<ScrollArrow />
		</main>
	);
};

export default MainPage;
