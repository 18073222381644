import React, { useState } from 'react';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import '../css/ScrollArrow.scss';

const ScrollArrow = () => {
	const [showScroll, setShowScroll] = useState(false);

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 400) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= 400) {
			setShowScroll(false);
		}
	};

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	window.addEventListener('scroll', checkScrollTop);

	return (
		<div className="scrollTop">
			<IoIosArrowDropupCircle
				onClick={scrollTop}
				className="scroll-obj"
				size={60}
				style={{
					height: '100px !important',
					size: '100px',
					opacity: showScroll ? '0.6' : '0',
				}}
			/>
		</div>
	);
};
export default ScrollArrow;
