import React from 'react';

import '../css/Solutions.scss';

import BgLineTop from '../images/bg-elements/solutions-top.png';
import BgLineBot from '../images/bg-elements/solutions-bot.png';
import BgLineTopMobile from '../images/bg-elements-mobile/solutions-top.png';
import BgLineBotMobile from '../images/bg-elements-mobile/solutions-bot.png';

const Solutions = () => {
	return (
		<div className="solutions" id="solutions">
			<div className="topline">
				<img
					src={BgLineTop}
					alt="Solutions Introduction"
					className="desktop"
					style={{ width: '100%' }}
				/>
				<img
					src={BgLineTopMobile}
					alt="Solutions Introduction"
					className="mobile"
					style={{ width: '100%' }}
				/>
				<div className="title-container">
					<h4>Our Solution</h4>
				</div>
			</div>
			<div className="solutions-content">
				<div className="introduction">
					<p className="l1">Introducing</p>
					<h2 className="l2">Barra-Fibers</h2>
					<p className="l3">
						Biodegradable, affordable fibers created
						<br /> out of unused natural resources.
					</p>
				</div>
			</div>
			<img
				src={BgLineBot}
				alt="Solutions Conculsion"
				className="desktop"
				style={{ width: '100%' }}
			/>
			<img
				src={BgLineBotMobile}
				alt="Solutions Conculsion"
				className="mobile"
				style={{ width: '100%' }}
			/>
		</div>
	);
};

export default Solutions;
