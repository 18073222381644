import React, { useState } from 'react';

import Navbar from '../components/Navbar';

import '../css/Intro.scss';

const Intro = () => {
	const [showScroll, setShowScroll] = useState(true);

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset < 400) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset >= 400) {
			setShowScroll(false);
		}
	};

	window.addEventListener('scroll', checkScrollTop);

	return (
		<div className="intro" id="intro">
			<Navbar />
			<div className="intro-text" style={{ opacity: showScroll ? '1' : '0' }}>
				<p className="top">WELCOME TO THE WORLD OF</p>
				<h3 className="middle">NATURAL FIBERS</h3>
				<h5 className="bottom">MADE FROM AGRICULTURAL WASTE</h5>
			</div>
		</div>
	);
};

export default Intro;
