import React from 'react';

import '../css/Products.scss';

import molded from '../images/products/molded.png';
import nonwoven from '../images/products/nonwoven.png';
import woven from '../images/products/woven.png';
import construction from '../images/products/construction.png';

import rect1 from '../images/products/rect1.png';
import rect2 from '../images/products/rect2.png';
import rect4 from '../images/products/rect3.png';
import rect3 from '../images/products/rect4.png';
import ProductTop from '../images/bg-elements/product-top.png';
import ProductTopMobile from '../images/bg-elements-mobile/product-top.png';

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.state = { a: rect1, b: rect2, c: rect3, d: rect4 };
	}

	hover(hover) {
		this.reset();
		if (hover === 'a') {
			this.setState({
				a: molded,
			});
		} else if (hover === 'b') {
			this.setState({
				b: nonwoven,
			});
		} else if (hover === 'c') {
			this.setState({
				c: woven,
			});
		} else if (hover === 'd') {
			this.setState({
				d: construction,
			});
		}
	}

	reset() {
		this.setState({
			a: rect1,
			b: rect2,
			c: rect3,
			d: rect4,
		});
	}

	render() {
		return (
			<div className="products" id="products">
				<div className="topline">
					<img
						src={ProductTop}
						alt="Products Intro"
						className="desktop"
						style={{ width: '100%' }}
					/>
					<img
						src={ProductTopMobile}
						alt="Products Intro"
						className="mobile"
						style={{ width: '100%' }}
					/>
					<div className="title-container">
						<h4>Our Products</h4>
					</div>
				</div>
				<div className="content-container">
					<div className="text-container">
						<h6>ENDLESS</h6>
						<h4>POSSIBILITIES</h4>
						<p>
							Our fibers have been successfully used in several end-applications
							with possible modulations in surface finish & strength and
							bio-based solutions for moisture resistance.
						</p>
					</div>

					<div className="square2 desktop">
						{['a', 'b', 'c', 'd'].map((item) => (
							<img
								onMouseEnter={() => this.hover(item)}
								onMouseLeave={() => this.reset()}
								className={item}
								alt={item}
								src={this.state[item]}
								key={item}
							/>
						))}
					</div>

					<div className="descriptions mobile">
						<h3>Molded Fibers</h3>
						<p>
							Molded fiber products have been developed for food and packaging
							uses such as compostable plates, containers, etc.
						</p>
						<h3>Non-Woven</h3>
						<p>
							Successful non-woven products using our fibers have included
							packaging paper, folding boxes, and cardboard boxes,
						</p>
						<h3>Construction</h3>
						<p>
							Our fibers have also been used for construction products including
							Medium Density Fiber Boards (MDF) and wet lap boards
						</p>
						<h3>
							Woven* <span>(in progress)</span>
						</h3>
						<p>Woven products include yarns and meshes (Work-in-progress*)</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Products;
