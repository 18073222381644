import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import '../css/Contact.scss';

import badge from '../images/badge.png';

const Contact = () => {
	const [message, setMessage] = useState('');

	const onClick = async () => {
		const form = document.getElementById('form');
		const firstInput = document.getElementById('first').value;
		const lastInput = document.getElementById('last').value;
		const emailInput = document.getElementById('email').value;
		const messageInput = document.getElementById('message').value;

		if (firstInput && lastInput && emailInput && messageInput) {
			await form.submit();
			form.reset();
			setMessage('Thanks for sending us a message!');
		} else {
			setMessage('Please fill in all the fields.');
		}
	};

	return (
		<div style={{ position: 'relative' }}>
			<div className="contact" id="contact">
				<div>
					<h3>Contact Us</h3>
					<h5>Interested?</h5>
					<p>
						Reach out to us via this form or at
						<br />
						info@barra-labs.com.
					</p>
					<form
						id="form"
						className="form"
						autoComplete="off"
						action="https://docs.google.com/forms/d/e/1FAIpQLSd8ykXm7c198MMDrDifxuMTN20o467knE9BaIeFLAAgS4V1oA/formResponse"
						target="dummyframe"
					>
						<div className="form-group">
							<input
								id="first"
								type="text"
								className="input-general input-small"
								autoComplete="off"
								placeholder="Name"
								name="entry.1612123012"
								novalidate
								required
							/>
						</div>
						<div className="form-group">
							<input
								id="last"
								type="text"
								className="input-general input-small"
								autoComplete="off"
								placeholder="Subject"
								name="entry.989966663"
								novalidate
								required
							/>
						</div>
						<iframe
							title="dummyframe"
							name="dummyframe"
							id="dummyframe"
							style={{ display: 'none' }}
						/>
						<div className="form-group">
							<input
								id="email"
								type="text"
								className="input-general input-small"
								autoComplete="off"
								placeholder="Message"
								name="entry.650411130"
								formnovalidate
								required
							/>
						</div>
						<div className="form-group">
							<textarea
								placeholder="Enter your message..."
								id="message"
								className="input-general"
								rows={8}
								autoComplete="off"
								name="entry.1147995591"
								required
							/>
						</div>
					</form>
					<div className="form">
						<Button
							className="contact-btn"
							onClick={onClick}
							variant="flat"
							size="xxl"
							type="submit"
						>
							<p>SUBMIT</p>
						</Button>
						{message && <p className="contact-text">{message}</p>}
					</div>
				</div>

				<div className="bottom-text">
					<p className="disclaimer">
						Copyright © 2020 Barracuda Technologies All Rights Reserved
					</p>
					<a href="https://www.webatberkeley.org" className="wdb-badge">
						<img
							src={badge}
							alt="Created by Web Development at Berkeley"
							className="select-disable"
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Contact;
