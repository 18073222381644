import React, { useState } from 'react';
import '../css/Tech.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Tech = () => {
	let [active, setActive] = useState(0);

	const materialArr = [
		'Rice Straw',
		'Bagasse',
		'Hemp Residue',
		'Palm EFB',
		'Bamboo',
		'Jute',
	];
	return (
		<div className={`tech tech-bg-${active}`} id="technology">
			<div className="text-container">
				<div className="title-container">
					<h4>Our technology</h4>
				</div>
				<h6>We change the word "waste" to</h6>
				<h3>"Resource".</h3>
				<p>
					Our fibers are produced sustainably using heat, water, and
					electricity. The water used is either recycled or can be used by the
					co-located facility.
				</p>
				<p>
					Barra-fibers are also biomass flexible, meaning we can work with any
					kind of agri-waste to produce high yields of highly tunable fibers at
					low costs.
				</p>
				<p className="examples">Examples:</p>
				<Container className="materials">
					<Row>
						{materialArr.map((material, index) => (
							<Col className="material" key={material}>
								<Button
									className={[
										'material-btn',
										active === index ? 'active-material' : null,
									]}
									variant="light"
									onClick={() => setActive(index)}
								/>
								{active === index ? (
									<h5 className="material-name">{material}</h5>
								) : null}
							</Col>
						))}
					</Row>
				</Container>
			</div>
			{active === 3 && (
				<div className="licensing">
					<a href="https://creativecommons.org/licenses/by-sa/3.0/">
						<p>Photo by CEPphoto, Uwe Aranes</p>
					</a>
				</div>
			)}
			{active === 5 && (
				<div className="licensing">
					<a href="https://creativecommons.org/licenses/by-sa/4.0/">
						<p>Photo by Yahya 2020</p>
					</a>
				</div>
			)}
		</div>
	);
};

export default Tech;
